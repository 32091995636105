import React, { useEffect, useState } from 'react';
import { fetchFlagsDetails } from './api/flagService.js';
import FlagItem from './FlagItem.js';
import './Tooltip.css';

const RedGreenFlagsSection = ({ caseId }) => { // Accept caseId prop
  console.log("Navigated to Transaction Monitoring with caseId:", caseId);
  const [caseDetails, setCaseDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadData() {
      try {
        console.log('recived the case idX: ' + caseId);
        const data = await fetchFlagsDetails(caseId);
        console.log(data);
        setCaseDetails(data);
      } catch (err) {
        setError('Failed to load case data.');
      }
    }
    if (caseId) loadData(); // Only call loadData if caseId is defined
  }, [caseId]); // Fetch data when caseId changes

  if (error) return <div>{error}</div>;
  if (!caseDetails) return <div>Loading...</div>;

  return (
    <div className="bg-white rounded-lg shadow-md p-5">
      <h2 className="text-xl font-bold mb-4">RED/GREEN FLAGS</h2>
      <div className="grid grid-cols-2 gap-4">
        <div className="red-flags pl-1">
          <ul className="space-y-2">
            {caseDetails.flags.filter(flag => flag.isRed).map((flag) => (
              <FlagItem key={flag.id} flag={flag} color="red" />
            ))}
          </ul>
        </div>
        <div className="green-flags pl-1">
          <ul className="space-y-2">
            {caseDetails.flags.filter(flag => !flag.isRed).map((flag) => (
              <FlagItem key={flag.id} flag={flag} color="green" />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RedGreenFlagsSection;
