import React, { useState } from 'react';

const TransactionsElliptic = () => {
    // Mock data for each tab
    const tabsData = [
        {
            id: 1,
            source: '1046675656728',
            destination: 'OBD-Bet',
            riskTrigger: '8.2',
            hops: 2,
            triggerValue: 'Gambling',
            value: 'USD 45,873',
        },
        {
            id: 2,
            source: '1077675656728',
            destination: 'CryptoExchange',
            riskTrigger: '6.4',
            hops: 1,
            triggerValue: 'Money Laundering',
            value: 'USD 32,500',
        },
        {
            id: 3,
            source: '2646670006728',
            destination: 'Darknet',
            riskTrigger: '9.1',
            hops: 3,
            triggerValue: 'Fraud',
            value: 'USD 80,000',
        },
    ];

    // State to track the active tab
    const [activeTab, setActiveTab] = useState(0); // Start with the first tab active

    return (
        <div className="bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-bold mb-4">TRANSACTIONS (ELLIPTIC)</h2>

            {/* Tabs */}
            <div className="flex space-x-1 mb-4 border-b border-gray-300">
                {tabsData.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => setActiveTab(index)}
                        className={`px-4 py-2 text-sm font-medium rounded-t-lg ${activeTab === index
                            ? 'bg-white shadow text-blue-500 border-b-2 border-blue-500 font-bold'
                            : 'text-gray-500 bg-gray-100'
                            }`}
                        style={{
                            borderBottomColor: activeTab === index ? 'transparent' : 'gray'
                        }}
                    >
                        {tab.source}
                    </button>
                ))}
            </div>

            {/* Transaction Table */}
            <div className="grid grid-cols-[2fr_2fr_1fr_0.5fr_1.5fr_1.5fr] text-sm font-semibold text-gray-500 mb-2 border-b border-gray-300 pb-1">
                <div>Source</div>
                <div>Destination</div>
                <div>Risk Trigger</div>
                <div>Hops</div>
                <div>Trigger Value</div>
                <div>Value</div>
            </div>

            {/* Display active tab's transaction data */}
            <div className="grid grid-cols-[2fr_2fr_1fr_0.5fr_1.5fr_1.5fr] text-sm py-2 border-b border-gray-300">
                <a href="www.example.com" className="text-blue-500">
                    {tabsData[activeTab].source.slice(0, 5)}...{tabsData[activeTab].source.slice(-5)}
                </a>
                <div>{tabsData[activeTab].destination}</div>
                <div>{tabsData[activeTab].riskTrigger}</div>
                <div>{tabsData[activeTab].hops}</div>
                <div>{tabsData[activeTab].triggerValue}</div>
                <div>{tabsData[activeTab].value}</div>
            </div>

            {/* See More Link */}
            <a href="www.example.com" className="text-blue-500 text-sm mt-4 inline-flex items-center">
                See More <span className="ml-1">&#9662;</span>
            </a>
        </div>
    );
};

export default TransactionsElliptic;