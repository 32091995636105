import React, { useState } from 'react';
import './Tooltip.css';

const RedFlag = () => <img src="/images/Red-Flag.svg" alt="Red Flag" className="w-4 h-4 mr-2" />;
const GreenFlag = () => <img src="/images/Green-Flag.svg" alt="Green Flag" className="w-4 h-4 mr-2" />;

const Tooltip = ({ content, position }) => (
    <div className="tooltip-content">
        {content}
    </div>
);

const FlagItem = ({ flag, color }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const renderExplanation = (explanation) => {
        if (flag.typeId === 1) {
            return (
                <div>
                    <table className="w-full border-collapse">
                        <thead>
                            <tr>
                                <th className="border-b border-gray-300 p-2">Date</th>
                                <th className="border-b border-gray-300 p-2">Amount (USD)</th>
                                <th className="border-b border-gray-300 p-2">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {explanation.transactions.map((tx, index) => (
                                <tr
                                    key={tx.id}
                                    className={`underline ${index === explanation.transactions.length - 1 ? 'bg-red-100' : ''}`} // Light red for the last row
                                >
                                    <td className="border-b border-gray-300 p-2">{tx.date}</td>
                                    <td className="border-b border-gray-300 p-2">{tx.amount} USD</td>
                                    <td className="border-b border-gray-300 p-2">
                                        {tx.typeId === 1 ? 'Deposit' : 'Withdrawal'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <br />

                    <p><strong>Target Wallet Address:</strong> {explanation.targetWalletAddress}</p>
                    <p><strong>Local In/Out Ratio:</strong> {explanation.localInOutRatio}%</p>
                    <p><strong>Global In/Out Ratio:</strong> {explanation.globalInOutRatio}%</p>
                    <p><strong>Previous Cases:</strong> {explanation.previousCases}</p>

                </div>
            );
        }
        else if (flag.typeId === 2) {
            return (
                <div>
                    <p><strong>P2P Transaction:</strong> {flag.explanation.P2PTransactionId}</p>
                    <p><strong>Is Merchant:</strong> {flag.explanation.IsMerchant ? 'Yes' : 'No'}</p>
                    {flag.explanation.IsMerchant && (<p><strong>Verified Merchant:</strong> {flag.explanation.VerifiedMerchant ? 'Yes' : 'No'}</p>)}
                    <p className="whitespace-nowrap"><strong>Withdrawal Amount:</strong> {flag.explanation.WithdrawalAmount} {flag.explanation.NonLocalCurrency}</p>
                    <p><strong>Local Currency:</strong> {flag.explanation.LocalCurrency}</p>
                    <p><strong>P2P vs On-Chain Ratio:</strong> {flag.explanation.P2PvsOnChainRatio}</p>
                </div>
            );
        }
        else {
            return <p>{explanation.detail}</p>;
        }
    };

    return (
        <li className="flex items-center relative">
            <div
                onMouseEnter={handleMouseEnter}
                onMouseMove={handleMouseEnter} // Track mouse movement to update tooltip position
                onMouseLeave={handleMouseLeave}
                className="relative"
            >
                {color === 'red' ? <RedFlag /> : <GreenFlag />}
                {showTooltip && <Tooltip content={renderExplanation(flag.explanation)} />}
            </div>
            <span className="ml-2">{flag.description}</span>
        </li>
    );
};

export default FlagItem;
