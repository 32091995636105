
const InternalExternalAddressesSection = () => {
    return (
      <div className="bg-white rounded-lg shadow-md p-4">
        <h2 className="text-xl font-bold mb-4">INTERNAL/EXTERNAL ADDRESSES</h2>
  
        {/* Top section - Crypto Deposit & Withdrawal */}
        <div className="grid grid-cols-2 gap-4 mb-4">
          {/* Crypto Deposit */}
          <div>
            <h3 className="text-sm font-semibold text-gray-500">CRYPTO DEPOSIT</h3>
            <p className="text-sm font-medium">3 source, no internal</p>
          </div>
  
          {/* Crypto Withdrawal */}
          <div>
            <h3 className="text-sm font-semibold text-gray-500">CRYPTO WITHDRAWAL</h3>
            <p className="text-sm font-medium">1 destination, no internal</p>
          </div>
        </div>
  
        {/* Table Header */}
        <div className='pl-3'>
          <div className="grid grid-cols-[1fr_1.2fr_1fr_1fr_1.5fr_1fr] text-sm font-semibold text-gray-500 border-b border-gray-300 pb-2 mb-2">
            <div>UID</div>
            <div>Nat.../Res</div>
            <div>SAR/LE</div>
            <div>Shared IP/Device</div>
            <div>Amounts</div>
            <div>Count</div>
          </div>
  
          {/* Table Row */}
          <div className="grid grid-cols-[1fr_1.2fr_1fr_1fr_1.5fr_1fr] text-sm py-2 border-b border-gray-300">
            <a href="www.example.com" className="text-blue-500">695006777</a>
            <div>EE/EE</div>
            <div>no/no</div>
            <div>no/no</div>
            <div>USD 100,873</div>
            <div>37</div>
          </div>
        </div>
      </div>
    );
  };

  export default InternalExternalAddressesSection;