// src/api/flagsService.js

export async function fetchCaseDetails(caseId) {
    const mockData = {
        "1268698306245": {
            userId: "user_001"
        },
        "1268698306211": {
            userId: "user_002"
        },
        "1268698306980": {
            userId: "user_003"
        },
        "1268698306002": {
            userId: "user_004"
        },
        "1268695006728": {
            userId: "user_005"
        },
    };

    // Return mock data based on the provided caseId
    return mockData[caseId] || null;
}
