// src/api/flagsService.js

// Function to handle "Rapid Movement"
function rapidMoneyMovement(flagId) {
    const mockData = {
        "1268698306245": {
            id: 1,
            typeId: 1,
            description: "Rapid Movement",
            isRed: true,
            explanation: {
                transactions: [
                    { id: 101, date: "2024-10-10", amount: 50000, typeId: 1 },
                    { id: 102, date: "2024-10-11", amount: 110000, typeId: 1 },
                    { id: 103, date: "2024-10-12", amount: 156000, typeId: 2 }
                ],
                targetWalletAddress: "0xabc123def456...",
                localInOutRatio: 97,
                globalInOutRatio: 95,
                previousCases: 4
            }
        },
        "1268698306211": {
            id: 1,
            typeId: 1,
            description: "Rapid Movement",
            isRed: true,
            explanation: {
                transactions: [
                    { id: 201, date: "2024-10-01", amount: 45000, typeId: 1 },
                    { id: 202, date: "2024-10-02", amount: 120000, typeId: 1 },
                    { id: 203, date: "2024-10-03", amount: 134000, typeId: 2 }
                ],
                targetWalletAddress: "0xdef789abc123...",
                localInOutRatio: 93,
                globalInOutRatio: 92,
                previousCases: 5
            }
        },
        "1268698306980": {
            id: 1,
            typeId: 1,
            description: "Rapid Movement",
            isRed: true,
            explanation: {
                transactions: [
                    { id: 301, date: "2024-10-15", amount: 60000, typeId: 1 },
                    { id: 302, date: "2024-10-16", amount: 105000, typeId: 1 },
                    { id: 303, date: "2024-10-17", amount: 145000, typeId: 2 }
                ],
                targetWalletAddress: "0x789abc456def...",
                localInOutRatio: 94,
                globalInOutRatio: 90,
                previousCases: 6
            }
        },
        "1268698306002": {
            id: 1,
            typeId: 1,
            description: "Rapid Movement",
            isRed: true,
            explanation: {
                transactions: [
                    { id: 401, date: "2024-11-05", amount: 72000, typeId: 1 },
                    { id: 402, date: "2024-11-06", amount: 90000, typeId: 1 },
                    { id: 403, date: "2024-11-07", amount: 130000, typeId: 2 }
                ],
                targetWalletAddress: "0x123abc456def...",
                localInOutRatio: 92,
                globalInOutRatio: 91,
                previousCases: 7
            }
        },
        "1268695006728": {
            id: 1,
            typeId: 1,
            description: "Rapid Movement",
            isRed: true,
            explanation: {
                transactions: [
                    { id: 501, date: "2024-11-18", amount: 85000, typeId: 1 },
                    { id: 502, date: "2024-11-19", amount: 92000, typeId: 1 },
                    { id: 503, date: "2024-11-20", amount: 125000, typeId: 2 }
                ],
                targetWalletAddress: "0x456def789abc...",
                localInOutRatio: 112,
                globalInOutRatio: 105,
                previousCases: 8
            }
        }
    };
    return mockData[flagId] || null;
}

// Function to handle "P2P Sell not in native currency"
function tradeInNonlocalCurrency(flagId) {
    const mockData = {
        "1268698306245": {
            id: 2,
            typeId: 2,
            description: "P2P Sell not in native currency",
            isRed: true,
            explanation: {
                P2PTransactionId: "N1782049774833302",
                IsMerchant: true,
                VerifiedMerchant: false,
                WithdrawalAmount: 50050,
                LocalCurrency: "RUB (Russian Ruble)",
                NonLocalCurrency: "USD",
                P2PvsOnChainRatio: "21%"
            }
        },
        "1268698306211": {
            id: 2,
            typeId: 2,
            description: "P2P Sell not in native currency",
            isRed: true,
            explanation: {
                P2PTransactionId: "N8996687486723748",
                IsMerchant: false,
                VerifiedMerchant: false,
                WithdrawalAmount: 93106,
                LocalCurrency: "BYN (Belarusian Ruble)",
                NonLocalCurrency: "GBP",
                P2PvsOnChainRatio: "73%"
            }
        },
        "1268698306980": {
            id: 2,
            typeId: 2,
            description: "P2P Sell not in native currency",
            isRed: true,
            explanation: {
                P2PTransactionId: "N9072966576709798",
                IsMerchant: true,
                VerifiedMerchant: false,
                WithdrawalAmount: 23264.5,
                LocalCurrency: "RUB (Russian Ruble)",
                NonLocalCurrency: "EUR",
                P2PvsOnChainRatio: "72%"
            }
        },
        "1268698306002": {
            id: 2,
            typeId: 2,
            description: "P2P Sell not in native currency",
            isRed: true,
            explanation: {
                P2PTransactionId: "N7183382067267266",
                IsMerchant: true,
                VerifiedMerchant: false,
                WithdrawalAmount: 51555.7,
                LocalCurrency: "RUB (Russian Ruble)",
                NonLocalCurrency: "GBP",
                P2PvsOnChainRatio: "36%"
            }
        },
        "1268695006728": {
            id: 2,
            typeId: 2,
            description: "P2P Sell not in native currency",
            isRed: true,
            explanation: {
                P2PTransactionId: "N0505130667405683",
                IsMerchant: true,
                VerifiedMerchant: false,
                WithdrawalAmount: 65254.12,
                LocalCurrency: "MXN (Mexican Peso)",
                NonLocalCurrency: "USD",
                P2PvsOnChainRatio: "52%"
            }
        }
    };
    return mockData[flagId] || null;
}

// Function to handle "SAR/STR User Interaction"
function sarUserInteraction(flagId) {
    const mockData = {
        "1268698306245": {
            id: 3,
            typeId: 3,
            description: "SAR/STR User Interaction",
            isRed: true,
            explanation: {
                detail: "User interacted with a flagged address in SAR/STR."
            }
        },
        "1268698306211": {
            id: 3,
            typeId: 3,
            description: "SAR/STR User Interaction",
            isRed: true,
            explanation: {
                detail: "User interacted with a flagged address in SAR/STR."
            }
        },
        "1268698306980": {
            id: 3,
            typeId: 3,
            description: "SAR/STR User Interaction",
            isRed: true,
            explanation: {
                detail: "User interacted with a flagged address in SAR/STR."
            }
        },
        "1268698306002": {
            id: 3,
            typeId: 3,
            description: "SAR/STR User Interaction",
            isRed: true,
            explanation: {
                detail: "User interacted with a flagged address in SAR/STR."
            }
        },
        "1268695006728": {
            id: 3,
            typeId: 3,
            description: "SAR/STR User Interaction",
            isRed: true,
            explanation: {
                detail: "User interacted with a flagged address in SAR/STR."
            }
        }
    };
    return mockData[flagId] || null;
}

// Function to handle "High Volume Transaction"
function highVolumeTransaction(flagId) {
    const mockData = {
        "1268698306245": {
            id: 4,
            typeId: 4,
            description: "High Volume Transaction",
            isRed: true,
            explanation: {
                detail: "User executed a high-volume transaction over USD 1 million."
            }
        },
        "1268698306211": {
            id: 4,
            typeId: 4,
            description: "High Volume Transaction",
            isRed: true,
            explanation: {
                detail: "User executed a high-volume transaction over USD 1 million."
            }
        },
        "1268698306980": {
            id: 4,
            typeId: 4,
            description: "High Volume Transaction",
            isRed: true,
            explanation: {
                detail: "User executed a high-volume transaction over USD 1 million."
            }
        },
        "1268698306002": {
            id: 4,
            typeId: 4,
            description: "High Volume Transaction",
            isRed: true,
            explanation: {
                detail: "User executed a high-volume transaction over USD 1 million."
            }
        },
        "1268695006728": {
            id: 4,
            typeId: 4,
            description: "High Volume Transaction",
            isRed: true,
            explanation: {
                detail: "User executed a high-volume transaction over USD 1 million."
            }
        }
    };
    return mockData[flagId] || null;
}

// Function to handle "Trading"
function trading(flagId) {
    const mockData = {
        "1268698306245": {
            id: 5,
            typeId: 5,
            description: "Trading (28 May, 2024 - 29 May, 2024)",
            explanation: {
                detail: "Customer is engaged in frequent trading with stable volumes."
            },
            isRed: false // This is a green flag
        },
        "1268698306211": {
            id: 5,
            typeId: 5,
            description: "Trading (02 Oct, 2024 - 04 Oct, 2024)",
            explanation: {
                detail: "Customer is engaged in frequent trading with stable volumes."
            },
            isRed: false // This is a green flag
        },
        "1268698306980": {
            id: 5,
            typeId: 5,
            description: "Trading (16 Oct, 2024 - 18 Oct, 2024)",
            explanation: {
                detail: "Customer is engaged in frequent trading with stable volumes."
            },
            isRed: false // This is a green flag
        },
        "1268698306002": {
            id: 5,
            typeId: 5,
            description: "Trading (06 Nov, 2024 - 08 Nov, 2024)",
            explanation: {
                detail: "Customer is engaged in frequent trading with stable volumes."
            },
            isRed: false // This is a green flag
        },
        "1268695006728": {
            id: 5,
            typeId: 5,
            description: "Trading (19 Nov, 2024 - 21 Nov, 2024)",
            explanation: {
                detail: "Customer is engaged in frequent trading with stable volumes."
            },
            isRed: false // This is a green flag
        }
    };
    return mockData[flagId] || null;
}

// Function to handle "Crypto Earnings"
function cryptoEarnings(flagId) {
    const mockData = {
        "1268698306245": {
            id: 6,
            typeId: 6,
            description: "Crypto Earnings",
            explanation: {
                detail: "Customer has incoming transactions consistent with crypto earnings."
            },
            isRed: false // This is a green flag
        },
        "1268698306211": {
            id: 6,
            typeId: 6,
            description: "Crypto Earnings",
            explanation: {
                detail: "Customer has incoming transactions consistent with crypto earnings."
            },
            isRed: false // This is a green flag
        },
        "1268698306980": {
            id: 6,
            typeId: 6,
            description: "Crypto Earnings",
            explanation: {
                detail: "Customer has incoming transactions consistent with crypto earnings."
            },
            isRed: false // This is a green flag
        },
        "1268698306002": {
            id: 6,
            typeId: 6,
            description: "Crypto Earnings",
            explanation: {
                detail: "Customer has incoming transactions consistent with crypto earnings."
            },
            isRed: false // This is a green flag
        },
        "1268695006728": {
            id: 6,
            typeId: 6,
            description: "Crypto Earnings",
            explanation: {
                detail: "Customer has incoming transactions consistent with crypto earnings."
            },
            isRed: false // This is a green flag
        }
    };
    return mockData[flagId] || null;
}

// Function to handle "Network Changes"
function networkChanges(flagId) {
    const mockData = {
        "1268698306245": {
            id: 7,
            typeId: 7,
            description: "Network Changes",
            explanation: {
                detail: "Customer's account shows network changes consistent with normal operations."
            },
            isRed: false // This is a green flag
        },
        "1268698306211": {
            id: 7,
            typeId: 7,
            description: "Network Changes",
            explanation: {
                detail: "Customer's account shows network changes consistent with normal operations."
            },
            isRed: false // This is a green flag
        },
        "1268698306980": {
            id: 7,
            typeId: 7,
            description: "Network Changes",
            explanation: {
                detail: "Customer's account shows network changes consistent with normal operations."
            },
            isRed: false // This is a green flag
        },
        "1268698306002": {
            id: 7,
            typeId: 7,
            description: "Network Changes",
            explanation: {
                detail: "Customer's account shows network changes consistent with normal operations."
            },
            isRed: false // This is a green flag
        },
        "1268695006728": {
            id: 7,
            typeId: 7,
            description: "Network Changes",
            explanation: {
                detail: "Customer's account shows network changes consistent with normal operations."
            },
            isRed: false // This is a green flag
        }
    };
    return mockData[flagId] || null;
}

// Main function to fetch the flags details
export async function fetchFlagsDetails(caseId) {
    const flagMethods = {
        "1268698306245": {
            userId: "user_001",
            flags: [
                rapidMoneyMovement(caseId),
                tradeInNonlocalCurrency(caseId),
                sarUserInteraction(caseId),
                highVolumeTransaction(caseId),
                trading(caseId),
                cryptoEarnings(caseId),
                networkChanges(caseId)
            ]
        },
        "1268698306211": {
            userId: "user_002",
            flags: [
                rapidMoneyMovement(caseId),
                tradeInNonlocalCurrency(caseId),
                sarUserInteraction(caseId),
                highVolumeTransaction(caseId),
                trading(caseId),
                cryptoEarnings(caseId),
                networkChanges(caseId)
            ]
        },
        "1268698306980": {
            userId: "user_003",
            flags: [
                rapidMoneyMovement(caseId),
                tradeInNonlocalCurrency(caseId),
                sarUserInteraction(caseId),
                highVolumeTransaction(caseId),
                trading(caseId),
                cryptoEarnings(caseId),
                networkChanges(caseId)
            ]
        },
        "1268698306002": {
            userId: "user_004",
            flags: [
                rapidMoneyMovement(caseId),
                tradeInNonlocalCurrency(caseId),
                sarUserInteraction(caseId),
                highVolumeTransaction(caseId),
                trading(caseId),
                cryptoEarnings(caseId),
                networkChanges(caseId)
            ]
        },
        "1268695006728": {
            userId: "user_005",
            flags: [
                rapidMoneyMovement(caseId),
                tradeInNonlocalCurrency(caseId),
                sarUserInteraction(caseId),
                highVolumeTransaction(caseId),
                trading(caseId),
                cryptoEarnings(caseId),
                networkChanges(caseId)
            ]
        }
    };

    // Return mock data based on the provided caseId
    return flagMethods[caseId] || null;
}