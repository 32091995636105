const AlertsSection = () => {
    return (
        <div className="bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-bold mb-4">ALERTS</h2>

            {/* Alerts Section */}
            <div className="mb-6 pl-3">
                {/* Table header for Alerts */}
                <div className="grid grid-cols-[2fr_1.5fr_0.5fr_2fr] text-sm font-semibold text-gray-500 mb-2 border-b border-gray-300 pb-1">
                    <div>Type</div>
                    <div>Amount</div>
                    <div>Count</div>
                    <div>Dates</div>
                </div>

                {/* Table row for Alerts */}
                <div className="grid grid-cols-[2fr_1.5fr_0.5fr_2fr] text-sm py-2 border-b border-gray-300">
                    <div>Transaction Limit</div>
                    <div>USD 45,873</div>
                    <div>8</div>
                    <div>24 May, 2024 - 26 May, 2024</div>
                </div>
            </div>

            {/* All Transactions Section */}
            <h2 className="text-xl font-bold mb-4">ALL TRANSACTIONS</h2>
            <div className='pl-3'>
                {/* Table header for All Transactions */}
                <div className="grid grid-cols-[2fr_2fr_1fr_1.5fr_1fr] text-sm font-semibold text-gray-500 mb-2 border-b border-gray-300 pb-1">
                    <div>Source</div>
                    <div>Destination</div>
                    <div>Action</div>
                    <div>Value</div>
                    <div>Date</div>
                </div>

                {/* Table row for All Transactions */}
                <div className="grid grid-cols-[2fr_2fr_1fr_1.5fr_1fr] text-sm py-2 border-b border-gray-300">
                    <div>1046675656728</div>
                    <div>User</div>
                    <div>Trading</div>
                    <div>USD 45,873</div>
                    <div>24 May, 2024</div>
                </div>
            </div>
            {/* See More link */}
            <a href="www.example.com" className="text-blue-500 text-sm mt-4 inline-flex items-center">
                See More <span className="ml-1">&#9662;</span>
            </a>
        </div>
    );
};

export default AlertsSection;