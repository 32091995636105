import React from 'react';

const LoadingPage = ({ loading }) => {
  if (!loading) return null; // Only render when loading is true

  return (
    <div className="flex justify-center items-center h-screen flex-col translate-y-[-10rem] translate-x-[6rem]">
      <h1 className="text-4xl font-bold" style={{ color: '#4F4F50' }}>Loading...</h1>
      <h1 className="text-4xl font-bold" style={{ color: '#4F4F50' }}>Please wait...</h1>
      <div className="mt-4 relative">
        <div className="w-16 h-16 border-4 border-blue-200 border-solid rounded-full animate-spin border-t-transparent"></div>
      </div>
    </div>
  );
};

export default LoadingPage;
