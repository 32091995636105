import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DecisionSaved from '../DecisionSaved.js';
import LoadingPage from '../LoadingPage.js';
import DecisionSection from './Components/DecisionSection/DecisionSection.js';
import RedGreenFlagsSection from './Components/RedGreenFlagsSection/RedGreenFlagsSection.js';
import UserProfileSection from './Components/UserProfileSection/UserProfileSection.js';
import AccountOverviewSection from './Components/AccountOverviewSection/AccountOverviewSection.js';
import RelatedCasesSection from './Components/RelatedCasesSection/RelatedCasesSection.js';
import InternalExternalAddressesSection from './Components/InternalExternalAddressesSection/InternalExternalAddressesSection.js';
import AlertsSection from './Components/AlertsSection/AlertsSection.js';
import TransactionsElliptic from './Components/TransactionsElliptic/TransactionsElliptic.js';
import { fetchCaseDetails } from './Components/api/caseService.js';
import { useNavigate } from 'react-router-dom';

// Main TransactionMonitoring component
const TransactionMonitoring = () => {
  const { caseId } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [dataPopulated, setDataPopulated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDecisionSaved, setIsDecisionSaved] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(); // Reference to the input element

  const navigateToCase = () => {
      const caseId = inputRef.current.value;
      navigate(`/transaction-monitoring/${caseId}`);
  };

  // Define handleCheck with useCallback to keep a stable reference
  const handleCheck = useCallback(async () => {

    let data = null;
    if (caseId && caseId.length > 0) {
      data = await fetchCaseDetails(caseId);
    }

    if (data === null) {
      setErrorMessage('Invalid Case ID...'); // Set error message
      return;
    }

    setErrorMessage(''); // Clear any previous error
    setIsLoaded(true);
    setTimeout(() => {
      setIsLoaded(false);
      setDataPopulated(true);
    }, 5000); // Simulate loading delay
  }, [caseId]); // Add caseId as a dependency

  // Function to handle saving the decision
  const handleSave = () => {
    setIsDecisionSaved(true); // Show the DecisionSaved component
  };

  // Function to close the DecisionSaved component
  const handleClose = () => {
    setIsDecisionSaved(false); // Hide the DecisionSaved component
    window.location.href = '/'; // Redirect to the main dashboard
  };

  // Handle key press (Enter key)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigateToCase(e.target.value);
    }
  };

  // Call handleCheck automatically when caseId changes
  useEffect(() => {
    if (caseId) {
      handleCheck();
    }
  }, [caseId, handleCheck]); // Add handleCheck to the dependency array

  return (
    <div className="container ml-4 ">
      {/* Initial Input Section (Case ID Entry) */}
      {!isLoaded && !dataPopulated && !isDecisionSaved && (
        <div className="sar-input">
          <p>Please enter the Case ID that you want to investigate and we will gather all the data you needed and suggest a decision for it.<br /> Don’t forget to mention if it was correct or not...</p>
          <div className="flex items-center mt-4">
            <input
              type="text"
              ref={inputRef}
              onKeyDown={handleKeyPress} // Detect Enter key 
              className="border p-2 mr-2"
              placeholder="Case ID"
            />
            <button onClick={navigateToCase} className="text-white p-2 rounded-md" style={{ backgroundColor: '#1151F3', marginLeft: 15, width: 94 }}>
              Check
            </button>
          </div>
          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
        </div>
      )}

      {/* Show LoadingPage while loading */}
      {isLoaded && <LoadingPage loading={isLoaded} />}

      {/* Main content after loading and when report is generated */}
      {!isLoaded && dataPopulated && !isDecisionSaved && (
        <div className="flex-1 flex p-3 space-x-4 overflow-hidden">
          {/* Left section - Flags and User Profile */}
          <div className="w-1/2 overflow-auto space-y-4">
            <RedGreenFlagsSection caseId={caseId} /> {/* Passing caseId here */}
            <UserProfileSection />
            <AccountOverviewSection />
            <RelatedCasesSection />
            <InternalExternalAddressesSection />
            <AlertsSection />
            <TransactionsElliptic />
          </div>

          {/* Right section - Decision */}
          <div className="w-1/2 overflow-auto">
            <DecisionSection handleSave={handleSave} />
          </div>
        </div>
      )}

      {/* Render DecisionSaved when the decision is saved */}
      {isDecisionSaved && (
        <div className="justify-center items-center w-full h-full pt-32 pl-64">
          <DecisionSaved decisionSaved={isDecisionSaved} handleClose={handleClose} />
        </div>
      )}
    </div>
  );
};

export default TransactionMonitoring;
