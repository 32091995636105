import React from 'react';

const DecisionSaved = ({ decisionSaved, handleClose }) => {
  if (!decisionSaved) return null; // Don't render if decisionSaved is false

  return (
    <div
      className="flex flex-col justify-center items-center min-h-screen bg-gray-100"
      style={{ paddingBottom: '600px' }}
    >
      {/* Checkmark inside circle */}
      <div className="flex justify-center items-center border-4 border-green-500 rounded-full w-24 h-24 mb-6">
        <div className="text-green-500 text-5xl">✓</div>
      </div>

      {/* Success message */}
      <h1 className="text-3xl font-bold mb-2">Decision Saved Successfully</h1>

      {/* Additional message */}
      <p className="mb-6">
        Your decision has been recorded and the case has been updated.
      </p>

      {/* Close button */}
      <button onClick={handleClose} className="bg-blue-500 text-white p-2 rounded-md">
        Close
      </button>
    </div>
  );
};

export default DecisionSaved;
