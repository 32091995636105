const AccountOverviewSection = () => {
    return (
        <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-bold mb-4">ACCOUNT OVERVIEW</h2>

            {/* Grid layout for account data */}
            <div className="grid grid-cols-2 gap-y-4">

                {/* Left Column */}
                <div>
                    <p className="text-gray-500 font-medium">TOTAL CRYPTO DEPOSITS / COUNT</p>
                    <p className="font-bold">USD 45,873 / 34</p>
                </div>

                <div>
                    <p className="text-gray-500 font-medium">TOTAL CRYPTO WITHDRAWAL / COUNT</p>
                    <p className="font-bold">USD 45,873 / 45</p>
                </div>

                {/* Left Column continued */}
                <div>
                    <p className="text-gray-500 font-medium">TOTAL FIAT DEPOSITS / COUNT</p>
                    <p className="font-bold">no</p>
                </div>

                <div>
                    <p className="text-gray-500 font-medium">TOTAL FIAT WITHDRAWAL / COUNT</p>
                    <p className="font-bold">no</p>
                </div>

                {/* Left Column continued */}
                <div>
                    <p className="text-gray-500 font-medium">ACCOUNT BALANCE</p>
                    <p className="font-bold">USD 45,873</p>
                </div>

                <div>
                    <p className="text-gray-500 font-medium">OUTBOUND/INBOUND RATIO</p>
                    <p className="font-bold">20%</p>
                </div>
            </div>
        </div>
    );
};

export default AccountOverviewSection;