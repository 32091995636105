import React, { useState } from 'react';
import DecisionSaved from './DecisionSaved.js';
import LoadingPage from './LoadingPage.js';

const SarGeneration = () => {
  const [userId, setUserId] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [isDecisionSaved, setIsDecisionSaved] = useState(false);
  const [decisionPopupOpen, setDecisionPopupOpen] = useState(false);
  const [newDecision, setNewDecision] = useState('');
  const [reason, setReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle input change
  const handleInputChange = (e) => {
    setUserId(e.target.value);
  };

  // Handle Generate button click or Enter key press
  const handleGenerate = () => {
    if (userId.length > 0) {
      setErrorMessage(''); // Clear any previous error
      setIsLoaded(true);
      setTimeout(() => {
        setIsLoaded(false);
        setGenerated(true);
      }, 5000);
    } else {
      setErrorMessage('Invalid User ID...'); // Set error message
    }
  };

  // Handle key press (Enter key)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleGenerate();
    }
  };

  // Handle correct decision
  const handleCorrectDecision = () => {
    setGenerated(false);
    setIsDecisionSaved(true);
  };

  // Handle incorrect decision
  const handleIncorrectDecision = () => {
    setDecisionPopupOpen(true);
  };

  // Handle saving decision change
  const handleSaveChanges = () => {
    // Logic to save changes goes here
    setDecisionPopupOpen(false);
    setGenerated(false);
    setIsDecisionSaved(true);
  };

  const handleClose = () => {
    setIsDecisionSaved(false);
    window.location.href = '/'; // Redirect to the main dashboard
  };

  return (
    <div className="container ml-4">
      {!isLoaded && !generated && !isDecisionSaved && (
        <div className="sar-input">
          <p>Please enter the User ID from your SAR case and we can generate the whole report.</p>
          <div className="flex items-center mt-4">
            <input
              type="text"
              value={userId}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress} // Detect Enter key press
              className="border p-2 mr-2"
              placeholder="User ID"
            />
            <button onClick={handleGenerate} className="text-white p-2 rounded-md" style={{ backgroundColor: '#1151F3', marginLeft: 15, width: 94 }}>
              Generate
            </button>
          </div>
          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
        </div>
      )}

      <LoadingPage loading={isLoaded}></LoadingPage>

      {generated && (
        <div className="report-section grid grid-cols-2 gap-4">
          <div className="col-span-2 md:col-span-1 bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">REPORT</h2>

            <div className="mb-6">
              <h3 className="font-bold mb-2">RED FLAGS/ACTIVITIES</h3>
              <p>User has been Tagged by the off-chain transaction monitoring screening with 3 Transaction Limit and 2 Similar Amounts Withdrawal / Deposits.
                <span className="text-red-600 font-semibold"> 1 red tag(s)</span> Identified.</p>
              <p className="mt-2">Escalate to the SAR Investigation team.</p>
            </div>

            <div className="mb-6">
              <h3 className="font-bold mb-2">INVOLVED PERSONS</h3>
              <p>Suspected User: <a href="www.example.com" className="text-blue-500 font-semibold">6345634</a></p>
              <p>Suspected Receiver: <a href="www.example.com" className="text-blue-500 font-semibold">DKF452342TX</a></p>
            </div>

            <div className="mb-6">
              <h3 className="font-bold mb-2">TRANSACTION ANALYSIS</h3>
              <p>Suspected Activity Period: 14 Dec 2023 - 5 Mar 2024</p>
              <p className="mt-2">Details:</p>
              <ul className="list-disc list-inside">
                <li>Withdrawal: $45,566</li>
                <li>Deposit: $56,903</li>
                <li>Number of transactions: 28</li>
                <li>Law Enforcements: <span className="font-bold">NO</span></li>
              </ul>
            </div>


            <div className="mb-6">
              <h3 className="font-bold mb-2">INVESTIGATION DESCRIPTION</h3>
              <p>The user is not a verified Merchant. The user accessed the platform from
                <span className="font-semibold"> 8 devices</span> and
                <span className="font-semibold"> 33 IP locations</span> using a VPN, no risk factors were identified. The user's
                <span className="font-semibold"> KYC is passed</span>. The user has been found sharing devices with other 4 internal users, sharing internal and external shared wallet addresses. Device shared users do not show any unusual red flags and are not subject to any LE inquiries or SAR filings, and no blocks or offboarding is observed.
              </p>
              <p className="mt-4">
                No blocks or SARs have been filed on the account. The user had not faced any LE inquiries or requests.
              </p>
              <p className="mt-4">
                <p className="mt-4">A review of the blockchain activity, using a blockchain monitoring tool Elliptic and on-chain alerts revealed several interactions with external address:</p>
                <a href="www.example.com" className="text-blue-500"> 56TCZMp97Ten4564</a> with <span className="text-red-600 font-semibold text-bold"><b>risk-score 8.9</b></span>, 3 transactions and total of $27,104, interaction with suspicious address.
              </p>
              <p className="mt-4 font-bold">Internal Transfer Addresses:</p>
              <p>The user made internal transfers with
                <span className="font-semibold"> 16 users</span>. 7 out of the user's top 10 internal transfer users were involved in
                <span className="font-semibold"> 16 LE requests</span> for activities like Fraud, Theft, Operating Gambling Houses, Embezzlement, Cybercrime, Drugs Supply, Copyright/Intellectual Property Crime, Computer Misuse Act, and
                <span className="text-red-600 font-semibold"><b> Money Laundering</b></span>, where 58 are Closed (1 closed recently: ID 5402346 in 2023-09-12), 2 Rejected, 2
                <span className="font-semibold"> Submitted</span>: ID 5402346 opened in 2023-06-01 and User ID 7434346 opened in 2023-08-18, and 1
                <span className="font-semibold"> In Process</span>: ID 5402346 opened in 2023-07-14. Additionally,
                <span className="font-semibold"> 4 SAR filings</span> were made. Among these, 3 were
                <span className="font-semibold"> Blocked and Off-boarded</span> and 1 was Closed.
              </p>
              <ol className="list-decimal list-inside mt-2">
                <li>
                  <a href="www.example.com" className="text-blue-500">ID 10466728</a>: Total crypto transaction: $322,656.54 (9.3% of total user transactions), withdrawal: $302,012.04, deposit: $22,644.50.
                </li>
                <li className="mt-2">
                  <a href="www.example.com" className="text-blue-500">ID 30679712</a>: Total crypto transaction: $234,954.12 (8.7% of total user transactions), withdrawal: $211,688.26, deposit: $21,345.56, Reason: Suspicious Transaction Activities, also has Shared Device with the user, Action:
                  <span className="font-semibold"> Blocked and Off-boarded (by SAR Agent)</span>.
                </li>
                <li className="mt-2">
                  <a href="www.example.com" className="text-blue-500">ID 4143819</a>: Total crypto transaction: $46,633.19, withdrawal: $32,012.04, deposit: $11,644.50,
                  <span className="font-semibold">P2P deposit</span>: $6,229.00, Reason:
                  <span className="font-semibold">Sanctioned</span> on-chain alert, Action:
                  <span className="font-semibold">Blocked and Off-boarded (by Sanction Team)</span>.
                </li>
              </ol>
              <p className="mt-4 font-bold">P2P Transactions:</p>
              <p>The user made internal P2P transfers with
                <span className="font-semibold"> 3 users</span>. 2 out of the user's top 10 P2P users faced
                <span className="font-semibold"> 6 LE requests</span> related to Fraud, Operating Gambling Houses, Embezzlement, Cybercrime, and Copyright/Intellectual Property Crime, where 24 are Closed. Additionally,
                <span className="font-semibold"> 5 SAR filings</span> were made. Among these, 2 were
                <span className="font-semibold"> Blocked</span>, 1 was
                <span className="font-semibold"> Off-boarded</span>, 1 is
                <span className="font-semibold"> In Process</span>, and 1 was Closed.
              </p>
              <ol className="list-decimal list-inside mt-2">
                <li>
                  <a href="www.example.com" className="text-blue-500">ID 4143819</a>: Total crypto transaction: $46,633.19, withdrawal: $32,012.04, deposit: $11,644.50,
                  <span className="font-semibold">P2P deposit</span>: $6,229.00, Reason:
                  <span className="font-semibold">Sanctioned</span> on-chain alert, Action:
                  <span className="font-semibold">Blocked and Off-boarded (by Sanction Team)</span>.
                </li>
                <li className="mt-2">
                  <a href="www.example.com" className="text-blue-500">ID 2689991</a>: Total P2P deposit: $10,000.00, Reason: Court order, Action:
                  <span className="font-semibold">Blocked (by SAR Agent)</span>.
                </li>
              </ol>

              <div className="mb-6">
                <p className="mt-4">
                  An open-source search on the client did not reveal any negative media.<br />
                  No Further RFI was previously requested from the user.
                </p>
              </div>

              <div className="mb-6">
                <h3 className="font-bold mb-2">CONCLUSION</h3>
                <p>
                  Request for additional information sent for transaction background with the address
                  <a href="www.example.com" className="text-blue-500"> 5675TCZMp97IeNS</a>.
                </p>
                <p className="mt-4 font-bold">
                  Escalating this case to MLRO, as there are a lot of suspicious activities that need special investigation.
                </p>
              </div>

            </div>
          </div>


          <div className="decision-section col-span-2 md:col-span-1 bg-white p-6 rounded-lg shadow-md">
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-gray-600 text-sm font-semibold">DECISION</h3>
                <h2 className="text-2xl font-bold">ESCALATE TO MLRO</h2>
              </div>
              <div className="flex flex-col items-center">
                <div className="w-16 h-16 rounded-full border-4 border-t-transparent border-green-500 relative mb-3" style={{ transform: 'rotate(-50deg)' }}>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-xl font-bold text-green-500" style={{ transform: 'rotate(50deg)' }}>80%</span>
                  </div>
                </div>
                <p className="text-gray-500 text-sm font-semibold">AI Suggestion Accuracy</p>
                <button className="mt-2 text-sm text-blue-500 bg-gray-100 px-4 py-1 rounded-md">
                  Export Explanation Report
                </button>
              </div>
            </div>

            <div className="mb-4">
              <h4 className="text-gray-600 text-sm font-semibold">CONCLUSION</h4>
              <p className="text-gray-700">Escalating this case to MLRO, as there are a lot of suspicious activities that need special investigation.</p>
            </div>

            <div className="mb-4">
              <h4 className="text-gray-600 text-sm font-semibold">ATTACHMENTS</h4>
              <div className="flex space-x-4">
                <a href="https://www.example.com/tm-report.doc" className="text-blue-500 underline">TM Report.doc</a>
                <a href="https://www.example.com/final-sar-report.doc" className="text-blue-500 underline">Final SAR Report.doc</a>
              </div>
            </div>

            <div className="flex space-x-4 mt-4">
              <button
                onClick={handleCorrectDecision}
                className="inline-flex items-center justify-center w-auto px-4 py-2"
                style={{ backgroundColor: '#009B51', color: 'white', borderRadius: '0.375rem' }}
              >
                <span className="text-lg">✔</span>
                <span className="ml-2 font-semibold">Correct, Escalate It</span>
              </button>

              <button
                onClick={handleIncorrectDecision}
                className="inline-flex items-center justify-center w-auto px-4 py-2"
                style={{ backgroundColor: '#CB1D3C', color: 'white', borderRadius: '0.375rem' }}
              >
                <span className="text-lg">✖</span>
                <span className="ml-2 font-semibold">Incorrect, Change the Decision</span>
              </button>


            </div>
          </div>

        </div>
      )}

      {decisionPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="popup-content p-6 bg-white shadow-lg rounded-lg w-96">
            <h2 className="text-2xl font-bold">Change the Decision</h2>
            <label className="block mt-4">New Decision</label>
            <select
              value={newDecision}
              onChange={(e) => setNewDecision(e.target.value)}
              className="border p-2 w-full"
            >
              <option value="escalate">Escalate to MLRO</option>
              <option value="discard">Discard</option>
            </select>

            <label className="block mt-4">Reason for Change</label>
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className="border p-2 w-full"
            />

            <div className="flex mt-4 justify-end space-x-2">
              <button
                onClick={() => setDecisionPopupOpen(false)}
                className="bg-gray-300 text-black p-2 rounded-md"
              >
                Cancel
              </button>

              <button
                onClick={handleSaveChanges}
                className="bg-blue-500 text-white p-2 rounded-md"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}


    <DecisionSaved decisionSaved={isDecisionSaved} handleClose={handleClose} />
    </div>
  );
};

export default SarGeneration;