const RelatedCasesSection = () => {
    return (
        <div className="bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-bold mb-4">RELATED CASES</h2>

            {/* Off-Chain Alerts Section */}
            <div className="mb-6">
                <h3 className="text-sm font-semibold text-gray-600 mb-2">
                    OFF-CHAIN ALERTS (1 OPEN, 1 CLOSED)
                </h3>
                {/* Table header */}
                <div className='pl-3'>
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm font-semibold text-gray-500 mb-2 border-b border-gray-300 pb-1">
                        <div>ID</div>
                        <div>Status</div>
                        <div>Reason</div>
                        <div>Opened</div>
                        <div>Closed</div>
                    </div>
                    {/* Table rows */}
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm py-2 border-b border-gray-300">
                        <a href="www.example.com" className="text-blue-500">695006728</a>
                        <div className="font-semibold text-gray-700">Open</div>
                        <div>Transaction Limit</div>
                        <div>24 May, 2024</div>
                        <div>-</div>
                    </div>
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm py-2 border-b border-gray-300">
                        <a href="www.example.com" className="text-blue-500">695006777</a>
                        <div className="font-semibold text-gray-700">Closed</div>
                        <div>Transaction Limit</div>
                        <div>6 May, 2023</div>
                        <div>on 24 May, 2023 by automatically</div>
                    </div>
                </div>
            </div>

            {/* On-Chain Alerts Section */}
            <div className="mb-6">
                <h3 className="text-sm font-semibold text-gray-600 mb-2">
                    ON-CHAIN ALERTS (1 OPEN)
                </h3>
                {/* Table header */}
                <div className='pl-3'>
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm font-semibold text-gray-500 mb-2 border-b border-gray-300 pb-1">
                        <div>ID</div>
                        <div>Status</div>
                        <div>Reason</div>
                        <div>Opened</div>
                        <div>Closed</div>
                    </div>
                    {/* Table rows */}
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm py-2 border-b border-gray-300">
                        <a href="www.example.com" className="text-blue-500">745006728</a>
                        <div className="font-semibold text-gray-700">Open</div>
                        <div>Gambling</div>
                        <div>11 May, 2024</div>
                        <div>-</div>
                    </div>
                </div>
            </div>

            {/* SAR/STR Reports Section */}
            <div className="mb-6">
                <h3 className="text-sm font-semibold text-gray-600 mb-2">
                    SAR/STR REPORTS (1 CLOSED)
                </h3>
                {/* Table header */}
                <div className='pl-3'>
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm font-semibold text-gray-500 mb-2 border-b border-gray-300 pb-1">
                        <div>ID</div>
                        <div>Status</div>
                        <div>Reason</div>
                        <div>Opened</div>
                        <div>Closed</div>
                    </div>
                    {/* Table rows */}
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm py-2 border-b border-gray-300">
                        <a href="www.example.com" className="text-blue-500">695555528</a>
                        <div className="font-semibold text-gray-700">Closed</div>
                        <div>Money Laundering</div>
                        <div>5 Dec, 2023</div>
                        <div>on 24 May, 2023 by John McCavey</div>
                    </div>
                </div>
            </div>

            {/* Ongoing Law Enforcement Cases Section */}
            <div>
                <h3 className="text-sm font-semibold text-gray-600 mb-2">
                    ONGOING LAW ENFORCEMENT CASES (1 OPEN)
                </h3>
                {/* Table header */}
                <div className='pl-3'>
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm font-semibold text-gray-500 mb-2 border-b border-gray-300 pb-1">
                        <div>ID</div>
                        <div>Status</div>
                        <div>Reason</div>
                        <div>Opened</div>
                        <div>Closed</div>
                    </div>
                    {/* Table rows */}
                    <div className="grid grid-cols-[1fr_0.7fr_1.5fr_1fr_2fr] text-sm py-2 border-b border-gray-300">
                        <a href="www.example.com" className="text-blue-500">695006000</a>
                        <div className="font-semibold text-gray-700">Open</div>
                        <div>Money Laundering</div>
                        <div>24 Jun, 2024</div>
                        <div>-</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RelatedCasesSection;