import React, { useState } from 'react';
import './DecisionSection.css'; // Import the CSS file for styling

const DecisionSection = ({ handleSave }) => {
    // State to track if the user is editing the decision and conclusion
    const [isEditing, setIsEditing] = useState(false);
    const [newDecision, setNewDecision] = useState("ESCALATE TO LEVEL 2");
    const [conclusion, setConclusion] = useState(
      `This report details the transaction analysis for TM analysts
      investigating alerts flagged for User ID: 6789. The user made 15 transactions
      over the last 30 days, totaling $12,450. Two high-risk transactions were flagged:
      a $5,000 transfer to a high-risk country and a $3,000 deposit from an unverified source.
      User information and transaction history have been reviewed, with suspicious activity
      noted for further investigation.`
    );
  
    // Track the new selected decision from the dropdown
    const [selectedDecision, setSelectedDecision] = useState("ESCALATE TO LEVEL 2");
  
    // Backup for original values to restore on cancel
    const [backupDecision, setBackupDecision] = useState(newDecision);
    const [backupConclusion, setBackupConclusion] = useState(conclusion);
  
    const handleEditClick = () => {
      setIsEditing(true); // Enable editing mode
      // Backup the current state for possible restoration on cancel
      setBackupDecision(newDecision);
      setBackupConclusion(conclusion);
    };
  
    const handleSaveClick = () => {
      // Update the decision text with the selected value
      setNewDecision(selectedDecision);
      setIsEditing(false); // Exit editing mode
  
      // Call the save function passed down as a prop (this will propagate to the page level)
      handleSave();
    };
  
    const handleCancelClick = () => {
      setNewDecision(backupDecision); // Restore the decision to the backup
      setConclusion(backupConclusion); // Restore the conclusion to the backup
      setIsEditing(false); // Exit editing mode without saving changes
    };
  
    return (
      <div className="decision-card">
        <div className="decision-header">
          <h4><b>DECISION</b></h4>
  
          {isEditing ? (
            <select
              value={selectedDecision}
              onChange={(e) => setSelectedDecision(e.target.value)}
              className="p-2 border rounded-md"
            >
              <option value="Close">Close</option>
              <option value="ESCALATE TO LEVEL 2">Escalate To Level 2</option>
              <option value="Request for Additional Information">Request for Additional Information</option>
            </select>
          ) : (
            <h2>{newDecision}</h2>
          )}
        </div>
  
        <div className="suggestion">
          <div className="flex flex-col items-center">
            <div
              className="w-16 h-16 rounded-full border-4 border-t-transparent border-green-500 relative mb-3"
              style={{ transform: "rotate(-50deg)" }}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <span
                  className="text-xl font-bold text-green-500"
                  style={{ transform: "rotate(50deg)" }}
                >
                  80%
                </span>
              </div>
            </div>
            <p className="text-gray-500 text-sm font-semibold">AI Suggestion Accuracy</p>
            <button className="mt-2 text-sm text-blue-500 bg-gray-100 px-4 py-1 rounded-md">
              Export Explanation Report
            </button>
          </div>
        </div>
  
        <hr className="separator" />
  
        <div className="close-case">
          <input type="checkbox" id="close-related-cases" />
          <label htmlFor="close-related-cases">
            Close all related 1 case(s) and refer to this one
          </label>
        </div>
  
        <br />
  
        <div className="conclusion font-bold">
          <h4><b>CONCLUSION</b></h4>
  
          {isEditing ? (
            <textarea
              value={conclusion}
              onChange={(e) => setConclusion(e.target.value)}
              className="w-full p-2 border rounded-md"
              rows="5"
            />
          ) : (
            <p>{conclusion}</p>
          )}
        </div>
  
        <br />
  
        <div className="attachments font-bold">
          <h4>ATTACHMENTS</h4>
          <div className="attachment-list">
            <a href="www.example.com">All Transactions.xls</a>
            <a href="www.example.com">OSINT Investigation Email.pdf</a>
            <a href="www.example.com">OSINT Investigation Name Surname.pdf</a>
            <a href="www.example.com">Final Report.doc</a>
          </div>
          <button className="text-gray-500 mt-2 flex items-center">
            +2 more files
          </button>
        </div>
  
        <div className="decision-buttons">
          {!isEditing && (
            <button className="correct" onClick={handleSaveClick}>
              <span>&#10004;</span> <span className='pl-1'>Correct, Escalate It</span>
            </button>
          )}
  
          {isEditing ? (
            <>
              <button
                className="submit-btn"
                onClick={handleSaveClick}
                style={{ backgroundColor: "#2F60DD", width: "75px", color: "white" }}
              >
                Submit
              </button>
              <button
                className="cancel-btn"
                onClick={handleCancelClick}
                style={{ backgroundColor: "#e0e0e0", width: "75px", color: "#555", marginLeft: "10px" }}
              >
                Cancel
              </button>
            </>
          ) : (
            <button className="incorrect" onClick={handleEditClick}>
              <span>&#10006;</span> <span className='pl-1'>Incorrect, Change the Decision</span>
            </button>
          )}
        </div>
      </div>
    );
  };

  export default DecisionSection;