// DashbordPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CheckmarkIcon = (color = "text-green-500", size = "w-5 h-5") => {
    return (
        <svg
            className={`ml-1 ${size} ${color}`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586l-3.293-3.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export const XIcon = (color = "text-red-500", size = "w-5 h-5") => {
    return (
        <svg
            className={`ml-1 ${size} ${color}`}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
            />
        </svg>
    );
};

const DashbordPage = ({ title }) => {

    
    const navigate = useNavigate();

    const handleRowClick = (caseId) => {
        navigate(`/transaction-monitoring/${caseId}`);
    };

   return title === "Compliance Efficiency Tool" && (
        <div className="bg-white shadow-md p-1 m-5 rounded-md">
            <table className="min-w-full text-left text-gray-500">
                <thead>
                    <tr className="border-b">
                        <th className="p-4">ID</th>
                        <th className="p-4">TAG NAME</th>
                        <th className="p-4">ASSIGNEE</th>
                        <th className="p-4">STATUS</th>
                        <th className="p-4">AI DECISION</th>
                        <th className="p-4">FINAL DECISION</th>
                        <th className="p-4">AI SUGGESTION / MATCH</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Row 1 */}
                    <tr className="border-b">
                        <td className="p-4 text-blue-500"> <span className="hover:cursor-pointer" onClick={(e) => handleRowClick(e.target.textContent)} >1268698306245</span></td>
                        <td className="p-4">KYT HR DW/WT</td>
                        <td className="p-4">Joe Harlow</td>
                        <td className="p-4">Resolved</td>
                        <td className="p-4">Passed + Withdraw Outer</td>
                        <td className="p-4">Passed + Withdraw Outer</td>
                        <td className="p-4 text-green-500 flex items-center">80% {CheckmarkIcon()}</td>
                    </tr>
                    {/* Row 2 */}
                    <tr className="border-b">
                        <td className="p-4 text-blue-500"> <span className="hover:cursor-pointer" onClick={(e) => handleRowClick(e.target.textContent)} >1268698306211</span></td>
                        <td className="p-4">KYT HR DW/WT</td>
                        <td className="p-4">Jane McCourtney</td>
                        <td className="p-4">Resolved</td>
                        <td className="p-4">Passed + Full Access</td>
                        <td className="p-4">Passed + Full Access</td>
                        <td className="p-4 text-yellow-500 flex items-center">65% {CheckmarkIcon()}</td>
                    </tr>
                    {/* Row 3 */}
                    <tr className="border-b">
                        <td className="p-4 text-blue-500"> <span className="hover:cursor-pointer" onClick={(e) => handleRowClick(e.target.textContent)} >1268698306980</span></td>
                        <td className="p-4">KYT HR DW/WT</td>
                        <td className="p-4">Carl Willow</td>
                        <td className="p-4">Resolved</td>
                        <td className="p-4">Passed + Withdraw Outer</td>
                        <td className="p-4">Passed + Full Access</td>
                        <td className="p-4 text-red-500 flex items-center">23% {XIcon()}</td>
                    </tr>
                    {/* Row 4 */}
                    <tr className="border-b">
                        <td className="p-4 text-blue-500"> <span className="hover:cursor-pointer" onClick={(e) => handleRowClick(e.target.textContent)} >1268698306002</span></td>
                        <td className="p-4">KYT HR DW/WT</td>
                        <td className="p-4">Mickey Nielson</td>
                        <td className="p-4">Resolved</td>
                        <td className="p-4">Reject + Full Block</td>
                        <td className="p-4">Passed + Full Block</td>
                        <td className="p-4 text-green-500 flex items-center">87% {XIcon()}</td>
                    </tr>
                    {/* Row 5 */}
                    <tr className="border-b">
                        <td className="p-4 text-blue-500"> <span className="hover:cursor-pointer" onClick={(e) => handleRowClick(e.target.textContent)} >1268695006728</span></td>
                        <td className="p-4">KYT HR DW/WT</td>
                        <td className="p-4">Cindy Valve</td>
                        <td className="p-4">Resolved</td>
                        <td className="p-4">KYC Request</td>
                        <td className="p-4">KYC Request</td>
                        <td className="p-4 text-green-500 flex items-center">90% {CheckmarkIcon()}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    ) 
}

export default DashbordPage;