import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TMETDashboard from './layouts/TMETDashboard.js';
import TransactionMonitoring from './pages/TransactionMonitoring/TransactionMonitoring.js'; // Import the TransactionMonitoring component
import SARGeneration from './pages/SarGeneration.js';

function App() {
  return (
      <Routes>
        <Route 
          path="/" 
          element={<TMETDashboard></TMETDashboard>} 
        />
        <Route 
          path="/transaction-monitoring/:caseId?" 
          element={<TMETDashboard title="Transaction Monitoring"><TransactionMonitoring /></TMETDashboard>} 
        />
        <Route 
          path="/sar-generation" 
          element={<TMETDashboard title="SAR Generation"><SARGeneration /></TMETDashboard>} 
        />
      </Routes>
  );
}

export default App;