// User Profile Section

// Simplified icon components for demo purposes
const ChevronDown = () => <span>▼</span>;
const Play = () => <span>▶</span>;

// ProfileItem component for reusable user profile fields
const ProfileItem = ({ label, value }) => (
    <div>
      <h4 className="text-sm text-gray-500">{label}</h4>
      <p className="font-medium">{value}</p>
    </div>
  );

const UserProfileSection = () => (
    <div className="bg-white rounded-lg shadow-md p-4">
      <h2 className="text-xl font-bold mb-4">USER PROFILE</h2>
      <div className="grid grid-cols-3 gap-4 mb-4">
        <ProfileItem label="USER TYPE" value="Standard" />
        <ProfileItem label="USER RISK" value="3" />
        <ProfileItem label="AGE" value="34" />
        <ProfileItem label="RESIDENCY" value="EE" />
        <ProfileItem label="NATIONALITY" value="EE" />
        <ProfileItem label="LANGUAGE" value="RU" />
        <ProfileItem label="NAME" value="Denis Potapov" />
        <ProfileItem label="LINKED ACCOUNTS" value="no" />
        <ProfileItem label="DEVICE SHARING" value="no" />
        <ProfileItem label="ACCOUNT BLOCK" value="19 May, 2024 by SAR Agent - has not provided additional information" />
        <ProfileItem label="TRANSACTION BLOCK" value="Unblock" />
        <ProfileItem label="ACCOUNT OFFBOARDED" value="no" />
      </div>
      <div className="mb-4">
        <h3 className="text-sm font-semibold mb-2">KYC</h3>
        <div className="flex space-x-2">
          <div className="relative">
            <img src="/images/user-selfi.png" alt="User selfie" className="w-32 h-32 object-cover rounded" />
            <button className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded">
              <Play />
            </button>
          </div>
          <img src="/images/user-id-card.png" alt="User ID" className="w-32 h-32 object-cover rounded" />
        </div>
        <button className="text-blue-500 mt-2 flex items-center">
          See More <ChevronDown />
        </button>
      </div>
      <div>
        <h3 className="text-xl font-bold mb-2">OSINT INVESTIGATION</h3>
        <div className="grid grid-cols-3 gap-4 mb-4">
          <div>
            <h4 className="text-sm font-semibold mb-1">NAME SURNAME SEARCH</h4>
            <a href="https://www.linkedin.com/feed/" className="text-blue-500 block">LinkedIn - Occupation: Software Developer, Salary Range: $40,000-$50,000</a>
          </div>
          <div>
            <h4 className="text-sm font-semibold mb-1">NEGATIVE MEDIA</h4>
            <span>no</span>
          </div>
          <div>
            <h4 className="text-sm font-semibold mb-1">EMAIL SEARCH</h4>
            <span>No data has been found</span>
          </div>
        </div>
        <div>
          <h4 className="text-sm font-semibold mb-1">IMAGES</h4>
          <img src="/images/osint-person.png" alt="OSINT Person From Search" className="w-32 h-32 object-cover rounded" />
        </div>
      </div>
    </div>
  );

  export default UserProfileSection;